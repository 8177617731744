<template>
    <div>
        <div class="study-cart">
            <div class="cart-container">
                <div class="total-cart">
                    <user-avatar />
                </div>
                <div class="img-list">
                    <img src="~@/assets/images/study/2.png" alt="" class="card-1" @click="toIndex">
                    <img src="~@/assets/images/study/9.png" alt="" class="card-2">
                    <img src="~@/assets/images/study/4.png" alt="" class="card-3">
                    <img src="~@/assets/images/study/6.png" alt="" class="card-4" @click="toPapers">
                    <img src="~@/assets/images/study/7.png" alt="" class="card-5">
                    <img src="~@/assets/images/study/3.png" alt="" class="card-6">
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import { mapState } from "vuex";
import userAvatar from "@/views/study/UserAvatar";

export default {
    data() {
        return {

        }
    },
    components: {
        userAvatar
    },
    computed: {
        ...mapState({
            token: (state) => state.user.token,
            user: (state) => state.user.user,
        }),
    },
    methods: {
        toIndex() {
            this.$router.push({
                name: 'index'
            })
        },
        toPapers() {
            //console.log(111)
            this.$router.push({
                path: '/papers',
            })
        },
        logOut() {
            this.axios
                    .post("/api/auth/logout", {token: this.token, get_type: this.type,})
                    .then((res) => {
                    })
                    .catch((err) => {
                        this.loading = false;
                    });
            this.$store.commit("logout");
            this.$router.push({name: "login"});
        }
    },
    beforeCreate() {
        document.querySelector('body').className += 'study-cart-container';
    },
    beforeDestroy() {
        document.querySelector('body').className = '';
    }
    
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/study.css';
.user-info {
    padding-top: 30px;
    height: 3.3rem;
    .user-name {
        margin-top: 1rem;
    }
    .user-level {
        margin: .1rem 0;
    }
}
</style>